import './Icons.scss';

const Icons = ({ name }) => {

    return (
        <>
            {
                name === 'mail' &&
                <svg className={`cmp-icon cmp-icon-${name}`} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                    <path d="M44 40H4c-2.206 0-4-1.794-4-4V12c0-2.206 1.794-4 4-4h40c2.206 0 4 1.794 4 4v24c0 2.206-1.794 4-4 4zM4 10c-1.103 0-2 .897-2 2v24c0 1.103.897 2 2 2h40c1.103 0 2-.897 2-2V12c0-1.103-.897-2-2-2H4z" />
                    <path d="M24 29.191 6.457 17.84a1 1 0 0 1 1.086-1.68L24 26.809 40.457 16.16a1 1 0 0 1 1.086 1.68L24 29.191zM6.001 34a1 1 0 0 1-.556-1.832l9-6a1 1 0 1 1 1.11 1.664l-9 6a1.004 1.004 0 0 1-.554.168zM41.999 34c-.19 0-.383-.055-.554-.168l-9-6a1 1 0 1 1 1.11-1.664l9 6A1 1 0 0 1 41.999 34z" />
                </svg>
            }
            {
                name === 'git' &&
                <svg className={`cmp-icon cmp-icon-${name}`} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="M256 48.3c28 0 55.2 5.6 80.8 16.7 24.8 10.7 47 26.1 66.1 45.7 19.1 19.6 34.2 42.5 44.7 67.9C458.5 205 464 233 464 261.9c0 45.8-13.9 89.5-40.2 126.3-12.6 17.7-27.8 33.2-45 46.2-15.8 11.9-33 21.3-51.2 28.2v-6.5c.1-11.9.2-29.8.2-50.4 0-12.4-2-22.5-4.9-30.5 37.2-8.3 92-33.7 92-125.4 0-24.6-7.1-46.5-21.2-65.1 3.1-12.9 5.5-35.6-5.1-63l-2.7-7.1-7.2-2.4c-1.5-.5-4.8-1.3-10-1.3-11.5 0-30.9 3.9-59.5 22.6-17-4.2-34.8-6.6-53.1-6.6h-.2c-18.3 0-36.2 2.4-53.1 6.6-28.6-18.7-48-22.7-59.5-22.7-5.2 0-8.5.9-10 1.4l-7.2 2.4-2.7 7.1c-10.6 27.5-8.2 50.2-5.1 63-14.1 18.7-21.2 40.6-21.2 65.1 0 49.6 16 79.9 36.6 98.5-8.1-6.6-18.6-12.1-31.2-13h-1.3c-13.1 0-22.3 5.4-25.2 14.7-4.7 14.8 9.8 25 14.6 28.4l.5.6 1.5.6c1.6 1 10.1 7 16.9 24.5 2 6.2 6.3 14.5 13.6 22.2-13.1-11.2-24.8-24-34.9-38.1C61.9 351.5 48 307.8 48 262c0-28.9 5.5-56.9 16.4-83.3 10.5-25.5 25.5-48.3 44.7-67.9 19.1-19.6 41.4-35 66.1-45.7C200.8 54 228 48.3 256 48.3M136.7 351c16.8 14.1 36.3 20.9 52.3 24.5-.8 2.2-1.5 4.5-2.1 6.9-3.4 1-7.5 1.8-11.7 1.8-10.9 0-19-5.4-25.6-16.9-2.4-4.6-6.8-10.7-12.9-16.3m3.7 88.6c9.8 5.3 22.1 8.8 37.2 8.8 2.2 0 4.4-.1 6.7-.2 0 2.8 0 5.5.1 7.9 0 2.4 0 4.6.1 6.5-15.6-5.8-30.3-13.5-44.1-23M256 32.3C132.3 32.3 32 135.2 32 262c0 101.5 64.2 187.5 153.2 217.9 1.4.3 2.6.4 3.8.4 8.3 0 11.5-6.1 11.5-11.4 0-5.5-.2-19.9-.3-39.1-8.4 1.9-15.9 2.7-22.6 2.7-43.1 0-52.9-33.5-52.9-33.5-10.2-26.5-24.9-33.8-24.9-33.8-19.5-13.7-.1-14.2 1.4-14.2h.1c22.5 2 34.3 23.9 34.3 23.9 11.2 19.6 26.2 25.2 39.6 25.2 10.5 0 20-3.4 25.6-6 2-14.8 7.8-24.9 14.2-30.7-49.7-5.8-102-25.5-102-113.5 0-25.1 8.7-45.6 23-61.6-2.3-5.8-10-29.2 2.2-60.8 0 0 1.6-.5 5-.5 8.1 0 26.4 3.1 56.6 24.1 17.9-5.1 37-7.6 56.1-7.7 19 .1 38.2 2.6 56.1 7.7 30.2-21 48.5-24.1 56.6-24.1 3.4 0 5 .5 5 .5 12.2 31.6 4.5 55 2.2 60.8 14.3 16.1 23 36.6 23 61.6 0 88.2-52.4 107.6-102.3 113.3 8 7.1 15.2 21.1 15.2 42.5 0 30.7-.3 55.5-.3 63 0 5.4 3.1 11.5 11.4 11.5 1.2 0 2.6-.1 4-.4C415.9 449.5 480 363.4 480 262c0-126.8-100.3-229.7-224-229.7z" />
                </svg>
            }

            {

                name === 'linkedin' &&
                <svg className={`cmp-icon cmp-icon-${name}`} viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
                    <path d="M44 1H6C3.243 1 1 3.243 1 6v38c0 2.757 2.243 5 5 5h38c2.757 0 5-2.243 5-5V6c0-2.757-2.243-5-5-5zm3 43c0 1.654-1.346 3-3 3H6c-1.654 0-3-1.346-3-3V6c0-1.654 1.346-3 3-3h38c1.654 0 3 1.346 3 3v38z" />
                    <path d="M8 42h8V19H8v23zm2-21h4v19h-4V21zM12 7c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5zm0 8c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zM31 19c-1.363 0-2.703.308-4 .916V19h-8v23h8V31c0-2.206 1.794-4 4-4s4 1.794 4 4v11h8V31c0-6.617-5.383-12-12-12zm10 21h-4v-9c0-3.309-2.691-6-6-6s-6 2.691-6 6v9h-4V21h4v2.521l1.561-1.057C27.996 21.493 29.489 21 31 21c5.514 0 10 4.486 10 10v9z" />
                </svg>
            }

            {
                name === 'cv' &&
                <svg className={`cmp-icon cmp-icon-${name}`} viewBox="0 0 846 846" xmlns="http://www.w3.org/2000/svg">
                    <path d="M423.33 279.52c54.2 0 98.47 44.26 98.47 98.47v45.71c0 11.4-9.25 20.65-20.65 20.65H345.51c-11.4 0-20.65-9.25-20.65-20.65v-45.71c0-54.2 44.27-98.47 98.47-98.47zM241.28 536.09c-27.16 0-27.16-41.3 0-41.3h364.1c27.16 0 27.16 41.3 0 41.3h-364.1zm0 177.71c-27.16 0-27.16-41.29 0-41.29h364.1c27.16 0 27.16 41.29 0 41.29h-364.1zm0-88.85c-27.16 0-27.16-41.3 0-41.3h364.1c27.16 0 27.16 41.3 0 41.3h-364.1zM119.91 10.4h465.97c5.7 0 10.86 2.31 14.6 6.05l140.87 140.87c4.03 4.03 6.05 9.32 6.05 14.6v643.69c0 11.4-9.25 20.65-20.65 20.65H119.91c-11.4 0-20.65-9.25-20.65-20.65V31.05c0-11.4 9.25-20.65 20.65-20.65zm457.42 41.29H140.55v743.28h565.56v-614.5L577.33 51.69zm-154 57.32c45.98 0 83.25 37.28 83.25 83.26 0 45.97-37.27 83.25-83.25 83.25s-83.25-37.28-83.25-83.25c0-45.98 37.27-83.26 83.25-83.26zm0 41.3c-23.18 0-41.96 18.78-41.96 41.96 0 23.17 18.78 41.96 41.96 41.96s41.96-18.79 41.96-41.96c0-23.18-18.78-41.96-41.96-41.96zm0 170.5c-31.4 0-57.18 25.77-57.18 57.18v25.07h114.36v-25.07c0-31.41-25.78-57.18-57.18-57.18z" />
                </svg>
            }

            {
                name === 'light' &&
                <svg className={`cmp-icon cmp-icon-${name}`} fill="none" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="m21 2-1 1M3 2l1 1M21 16l-1-1M3 16l1-1M9 18h6M10 21h4M12 3C8 3 5.952 4.95 6 8c.023 1.487.5 2.5 1.5 3.5S9 13 9 15h6c0-2 .5-2.5 1.5-3.5h0c1-1 1.477-2.013 1.5-3.5.048-3.05-2-5-6-5Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            }

            {
                name === 'dark' &&
                <svg className={`cmp-icon cmp-icon-${name}`} fill="none" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 18h6M10 21h4M16.5 11.5c1-1 1.477-2.013 1.5-3.5.048-3.05-2-5-6-5-1.168 0-2.169.166-3 .477M9 15c0-2-.5-2.5-1.5-3.5S6.023 9.487 6 8a5.618 5.618 0 0 1 .168-1.5M3 3l18 18" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            }
            {
                name === 'error' &&
                <svg className={`cmp-icon cmp-icon-${name}`} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22 13c0 5.523-4.477 10-10 10S2 18.523 2 13 6.477 3 12 3s10 4.477 10 10z" fill="#c0392b" />
                    <path d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10z" fill="#e74c3c" />
                    <path d="m7.05 9.4 3.536 3.6-3.536 3.5 1.415 1.4L12 14.4l3.536 3.5 1.414-1.4-3.536-3.5 3.536-3.6L15.536 8 12 11.5 8.465 8 7.05 9.4z" fill="#c0392b" />
                    <path d="m7.05 8.4 3.536 3.6-3.536 3.5 1.415 1.4L12 13.4l3.536 3.5 1.414-1.4-3.536-3.5 3.536-3.6L15.536 7 12 10.5 8.465 7 7.05 8.4z" fill="#ecf0f1" />
                </svg>
            }
            {
                name === 'gitlab' &&
                <svg
                    style={{
                        color: "black"
                    }}
                    className={`cmp-icon cmp-icon-${name}`}
                    xmlns="http://www.w3.org/2000/svg"
                    width="55" height="55" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round"  >
                    <path d="M22.65 14.39L12 22.13 1.35 14.39a.84.84 0 0 1-.3-.94l1.22-3.78 2.44-7.51A.42.42 0 0 1 4.82 2a.43.43 0 0 1 .58 0 .42.42 0 0 1 .11.18l2.44 7.49h8.1l2.44-7.51A.42.42 0 0 1 18.6 2a.43.43 0 0 1 .58 0 .42.42 0 0 1 .11.18l2.44 7.51L23 13.45a.84.84 0 0 1-.35.94z"></path>
                </svg>

            }



        </>
    )
}

export default Icons;