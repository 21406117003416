import cv from './cv.pdf'
import './CV.css'

const CV = () => {
    return (
      
          <embed className='cmp-cv-container' src={cv}  ></embed>
      
    )
}
export default CV